var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { RoleCategories } from '../../roleEnums';
import { TrailMapType } from '../../trail-maps/models';
import { FilterType } from '../../data-filters/models';
const recordTypeId = 'AutopayPlan';
let AutopayPlan = class AutopayPlan {
    //pk
    id;
    //fks
    policyholderId;
    policyId;
    policyPaymentMethodId;
    //jdata
    policyPaymentMethod;
    policy;
    policyholder;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    status;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], AutopayPlan.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'PolicyHolder' })
], AutopayPlan.prototype, "policyholderId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Policy' })
], AutopayPlan.prototype, "policyId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'uuid',
        referenceType: 'PolicyPaymentMethod',
    })
], AutopayPlan.prototype, "policyPaymentMethodId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'PolicyPaymentMethod' })
], AutopayPlan.prototype, "policyPaymentMethod", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Policy' })
], AutopayPlan.prototype, "policy", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'PolicyHolder', label: 'Policyholder' })
], AutopayPlan.prototype, "policyholder", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], AutopayPlan.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], AutopayPlan.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], AutopayPlan.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], AutopayPlan.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], AutopayPlan.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], AutopayPlan.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], AutopayPlan.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], AutopayPlan.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], AutopayPlan.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({
        recordTypeId, label: 'Status', codeList: [
            { code: 'enabled', description: 'Enabled' },
            { code: 'disabled', description: 'Disabled' },
        ]
    })
], AutopayPlan.prototype, "status", void 0);
AutopayPlan = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        usesNewDataModel: true,
        displayNameSingular: 'Autopay Plan',
        displayNamePlural: 'Autopay Plans',
        supports: {
            views: true
        },
        dataModelCustomization: {
            allowCustomFields: true
        },
        quickFilterFields: [
            { recordType: recordTypeId, fieldPath: 'policyholder.displayName', filterType: FilterType.Contains, label: 'Policyholder' },
            { recordType: recordTypeId, fieldPath: 'policy.policyNumber', filterType: FilterType.Equals },
            { recordType: recordTypeId, fieldPath: 'status', filterType: FilterType.Equals }
        ],
        trailMapTypes: [TrailMapType.HomePage],
        roleCategory: RoleCategories.AutopayPlans,
    })
], AutopayPlan);
export { AutopayPlan };
