var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'ModuleSetting';
let ModuleSetting = class ModuleSetting {
    id;
    name;
    category;
    subcategory;
    description;
    value;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], ModuleSetting.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId })
], ModuleSetting.prototype, "name", void 0);
__decorate([
    FieldData({ recordTypeId })
], ModuleSetting.prototype, "category", void 0);
__decorate([
    FieldData({ recordTypeId })
], ModuleSetting.prototype, "subcategory", void 0);
__decorate([
    FieldData({ recordTypeId })
], ModuleSetting.prototype, "description", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', hasColumn: true, jsonStorage: true })
], ModuleSetting.prototype, "value", void 0);
ModuleSetting = __decorate([
    RecordTypeData({
        id: recordTypeId,
        usesNewDataModel: true,
        hasBackingTable: true,
        displayNameSingular: 'Module Setting',
        displayNamePlural: 'Module Settings',
        dataModelCustomization: {
            hideFromAdmin: true
        },
    })
], ModuleSetting);
export { ModuleSetting };
