import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateFnsAdapter, MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgDompurifyModule, NgDompurifyPipe, SANITIZE_STYLE } from '@tinkoff/ng-dompurify';
import { NgForTrackByIdDirective, NgForTrackByPropDirective } from './directives/trackby-directive';
import { NotNullorEmptyPipe, NullorEmptyPipe } from './pipes/string-is-null-empty.pipe';

import { CheckboxValueDirective } from './directives/checkbox-value.directive';
import { CommonModule } from '@angular/common';
import { CompareWithByIdDirective } from './directives/compare-with-by.directive';
import { DateMaskDatePickerFormatDirective } from './directives/short-date-datepicker.directive';
import { DisableFormComponent } from './directives/disable-form.directive';
import { ErrorMessagePipe } from './pipes/error-message.pipe';
import { FileUploaderPanelComponent } from './components/file-uploader-panel/file-uploader-panel.component';
import { FileViewerModalComponent } from './components/file-viewing/file-viewer-modal/file-viewer-modal.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HasPermissionDirective } from 'app/shared/directives/has-permission.directive';
import { IsLoadingModule } from '@service-work/is-loading';
import { MaskitoDirective } from '@maskito/angular';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MonthYearDatePickerFormatDirective } from './directives/month-year-datepicker.directive';
import { NgModule } from '@angular/core';
import { NgxFileDropModule } from 'ngx-file-drop';
import { PdfAdminViewerComponent } from './components/file-viewing/pdf-admin-viewer/pdf-admin-viewer.component';
import { PdfViewerComponent } from './components/file-viewing/pdf-viewer/pdf-viewer.component';
import { PreventDoubleClickDirective } from './directives/prevent-double-click';
import { QuillModule } from 'ngx-quill';
import { RelativeDateHelperComponent } from './components/relative-date-helper/relative-date-helper.component';
import { RouterModule } from '@angular/router';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { SlugifyPipe } from './pipes/slugify.pipe';
import { TbAutocompleteComponent } from './components/tb-autocomplete/tb-autocomplete.component';
import { TbAutocompleteMultiComponent } from './components/tb-autocomplete-multi/tb-autocomplete-multi.component';
import { TbCogLinkComponent } from './components/tb-cog-link/tb-cog-link.component';
import { TbCollapseContainerComponent } from './components/tb-collapse-container/tb-collapse-container.component';
import { TbCollapseContainerDetailComponent } from './components/tb-collapse-container/tb-collapse-container-detail/tb-collapse-container-detail.component';
import { TbCollapseContainerHeaderComponent } from './components/tb-collapse-container/tb-collapse-container-header/tb-collapse-container-header.component';
import { TbContainerComponent } from './components/tb-container/tb-container.component';
import { TbContentPanelComponent } from './components/tb-content-panel/tb-content-panel.component';
import { TbContentPanelDetailComponent } from './components/tb-content-panel/tb-content-panel-detail/tb-content-panel-detail.component';
import { TbContentPanelHeaderComponent } from './components/tb-content-panel/tb-content-panel-header/tb-content-panel-header.component';
import { TbDatepickerComponent } from './components/tb-datepicker/tb-datepicker.component';
import { TbEmailSubformComponent } from './components/tb-email-subform/tb-email-subform.component';
import { TbInfoTooltipComponent } from './components/tb-info-tooltip/tb-info-tooltip.component';
import { TbLoaderPlaceholderComponent } from './components/tb-loader-placeholder/tb-loader-placeholder.component';
import { TbLongTextOptionDirective } from './components/tb-long-text-option/tb-long-text.directive';
import { TbPageHeaderComponent } from './components/tb-page-header/tb-page-header.component';
import { TbRelativeDatePickerComponent } from './components/tb-relative-date-picker/tb-relative-date-picker.component';
import { TbSchedulePanelComponent } from './components/tb-schedule-panel/tb-schedule-panel.component';
import { UserTypeDirective } from './directives/user-type.directive';
import { UtcDatePipe } from './pipes/utc-date.pipe';
import { ValidateDateDirective } from './directives/validate-date.directive';
import { enUS } from 'date-fns/locale';
import { ɵɵsanitizeStyle } from '@angular/core';
import { SplitCamelCasePipe } from './pipes/split-camel-case.pipe';

const sharedModules = [
  MatTooltipModule,
  CommonModule,
  FormsModule,
  IsLoadingModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  MatButtonModule,
  MatIconModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatAutocompleteModule,
  NgxFileDropModule,
  MatTooltipModule,
  MatChipsModule,
  RouterModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSelectModule,
  MatDateFnsModule,
  MatTabsModule,
  MaskitoDirective,
  NgDompurifyModule,
];

@NgModule({
  imports: [...sharedModules, MatMenuModule, QuillModule.forRoot()],
  exports: [
    ...sharedModules,
    TbContainerComponent,
    TbPageHeaderComponent,
    TbContentPanelComponent,
    TbContentPanelHeaderComponent,
    TbContentPanelDetailComponent,
    TbCollapseContainerComponent,
    TbCollapseContainerHeaderComponent,
    TbCollapseContainerDetailComponent,
    TbLoaderPlaceholderComponent,
    TbAutocompleteComponent,
    TbAutocompleteMultiComponent,
    TbCogLinkComponent,
    SlugifyPipe,
    SplitCamelCasePipe,
    UtcDatePipe,
    NotNullorEmptyPipe,
    NullorEmptyPipe,
    ErrorMessagePipe,
    FileUploaderPanelComponent,
    HasPermissionDirective,
    SanitizeHtmlPipe,
    DisableFormComponent,
    TbInfoTooltipComponent,
    QuillModule,
    TbEmailSubformComponent,
    PreventDoubleClickDirective,
    NgForTrackByPropDirective,
    CompareWithByIdDirective,
    MonthYearDatePickerFormatDirective,
    DateMaskDatePickerFormatDirective,
    UserTypeDirective,
    CheckboxValueDirective,
    TbSchedulePanelComponent,
    PdfAdminViewerComponent,
    RelativeDateHelperComponent,
    ValidateDateDirective,
    TbRelativeDatePickerComponent,
    TbLongTextOptionDirective,
  ],
  declarations: [
    TbContainerComponent,
    TbPageHeaderComponent,
    TbContentPanelComponent,
    TbContentPanelHeaderComponent,
    TbContentPanelDetailComponent,
    TbCollapseContainerComponent,
    TbCollapseContainerHeaderComponent,
    TbCollapseContainerDetailComponent,
    TbAutocompleteComponent,
    TbAutocompleteMultiComponent,
    PdfViewerComponent,
    PdfAdminViewerComponent,
    FileViewerModalComponent,
    SlugifyPipe,
    SplitCamelCasePipe,
    NotNullorEmptyPipe,
    NullorEmptyPipe,
    ErrorMessagePipe,
    FileUploaderPanelComponent,
    HasPermissionDirective,
    SanitizeHtmlPipe,
    TbLoaderPlaceholderComponent,
    DisableFormComponent,
    TbInfoTooltipComponent,
    TbEmailSubformComponent,
    TbAutocompleteComponent,
    PreventDoubleClickDirective,
    NgForTrackByIdDirective,
    NgForTrackByPropDirective,
    CompareWithByIdDirective,
    MonthYearDatePickerFormatDirective,
    DateMaskDatePickerFormatDirective,
    TbCogLinkComponent,
    UserTypeDirective,
    CheckboxValueDirective,
    TbSchedulePanelComponent,
    UtcDatePipe,
    PdfAdminViewerComponent,
    RelativeDateHelperComponent,
    TbDatepickerComponent,
    ValidateDateDirective,
    TbRelativeDatePickerComponent,
    TbLongTextOptionDirective,
  ],
  providers: [
    SlugifyPipe,
    SplitCamelCasePipe,
    NotNullorEmptyPipe,
    NullorEmptyPipe,
    SanitizeHtmlPipe,
    ErrorMessagePipe,
    UtcDatePipe,
    NgDompurifyPipe,
    { provide: DateAdapter, useClass: DateFnsAdapter },
    { provide: MAT_DATE_LOCALE, useValue: enUS },
    {
      provide: SANITIZE_STYLE,
      useValue: ɵɵsanitizeStyle,
    },
  ],
})
export class SharedModule { }
