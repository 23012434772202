import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';

import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[tbLongTextOption]',
  providers: [MatTooltip],
})
export class TbLongTextOptionDirective implements AfterViewInit {
  @Input() tbLongTextOption: string;

  constructor(private el: ElementRef, private renderer: Renderer2, private tooltip: MatTooltip) {}

  ngAfterViewInit(): void {
    // Delay processing to allow Angular Material to render its internal structure
    setTimeout(() => {
      const matOptionText = this.el.nativeElement.querySelector('.mat-option-text');
      if (matOptionText) {
        this.applyTruncate(matOptionText);
      } else {
        console.warn('tbLongTextOption: .mat-option-text not found');
      }
    });
  }

  @HostListener('mouseover') mouseover() {
    this.tooltip.show();
  }
  @HostListener('mouseleave') mouseleave() {
    this.tooltip.hide();
  }
  private applyTruncate(element: HTMLElement): void {
    // Preserve existing text content

    this.tooltip.message = this.tbLongTextOption || element.textContent?.trim() || '';
    this.tooltip.position = 'below';

    const span = this.renderer.createElement('span');
    this.renderer.addClass(span, 'truncate');

    // Move all child nodes (preserving HTML) into the new span
    while (element.firstChild) {
      this.renderer.appendChild(span, element.firstChild);
    }

    // Clear the existing content and append the new wrapper
    this.renderer.appendChild(element, span);
  }
}
