import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { LookupResult } from 'portal-commons/dist/lookups/models';
import { DataFilterStore } from '../../services/data-filter.store';
import { FilterType, QuickSearchField } from 'portal-commons/dist/data-filters/models';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, filter, map, of, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { CodesApiService } from 'app/core/codes/codes-api.service';
import { DataFilterQuickSearchComponent } from '../data-filter-quick-search/data-filter-quick-search.component';
import { DataModelRecordTypeField } from 'portal-commons/dist/data-model/record-types';
import { DataModelStoreService } from 'app/core/data-model/services/data-model.store';
import { LookupRecord } from '../../models/model';
import type { MaskitoOptions } from '@maskito/core';
import { TrailMapConfigService } from 'app/core/trail-maps/trail-map-config.service';
import { convertEnumToSet } from 'app/core/utils/enum-helper';
import maskDate from '../../../../shared/masks/datemask';
import { sortBy } from 'lodash-es';
import { getDateOnlyString } from 'app/core/utils/form-helper';

@UntilDestroy()
@Component({
  selector: 'tb-data-filter-quick-search-field',
  templateUrl: './data-filter-quick-search-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataFilterQuickSearchFieldComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() quickSearchField: QuickSearchField;
  readonly options: MaskitoOptions = maskDate;
  fieldWidth: QuickSearchField['width'] = 'medium';
  fieldLabel: string | undefined;

  tmConfig = inject(TrailMapConfigService);
  parent = inject(DataFilterQuickSearchComponent);
  builder = inject(FormBuilder);
  dataModelStore = inject(DataModelStoreService);
  filterStore = inject(DataFilterStore);
  codesApi = inject(CodesApiService);

  @HostBinding('className') classBinding = 'flex flex-row';

  dataModelField$!: Observable<DataModelRecordTypeField | undefined>;
  controlType$!: Observable<string>;
  enumCodes$: Observable<LookupRecord[]>;
  codeSetCodes$: Observable<LookupRecord[]>;
  mapAreas$: Observable<LookupRecord[]>;
  recordTypes$: Observable<LookupRecord[]>;
  lookupRecordType: string | undefined = undefined;

  ngOnInit(): void {
    this.fieldLabel =
      this.quickSearchField.label ??
      this.dataModelStore
        .parsePath(this.quickSearchField.recordType, this.quickSearchField.fieldPath)
        ?.map((m) => m.label)
        .join(' > ');
    if (this.quickSearchField.width) {
      this.fieldWidth = this.quickSearchField.width;
    }

    this.dataModelField$ = of(
      this.dataModelStore.getFieldFromPath(
        this.quickSearchField.recordType,
        this.quickSearchField.fieldPath,
      ),
    ).pipe(shareReplay(1));
    this.dataModelField$.subscribe();

    this.controlType$ = this.dataModelField$.pipe(
      withLatestFrom(this.filterStore.quickSearchFilters$),
      tap(([field, filters]) => {
        const filter = filters?.find(f => f.fieldPath === this.quickSearchField.fieldPath);
        const controlName = this.quickSearchField.fieldPath;
        if (this.quickSearchField.filterType === FilterType.Between) {
          this.formGroup.addControl(`${controlName}.param1`, this.builder.control(filter?.searchParameter1 ?? ''));
          this.formGroup.addControl(`${controlName}.param2`, this.builder.control(filter?.searchParameter2 ?? ''));
        } else {
          this.formGroup.addControl(controlName, this.builder.control(filter?.searchParameter1 ?? ''));
        }
      }),
      map(([field, filters]) => {
        if (!field) {
          return 'default';
        }
        if (field.codeEnum) {
          return 'enum';
        }
        if (field.fieldType === 'boolean') {
          return 'boolean';
        }
        if (field.fieldType === 'datetime') {
          return 'datetime';
        }
        if (field.fieldType === 'date') {
          return 'date';
        }

        if (field.fieldType === 'mapArea') {
          return 'mapArea';
        }

        if (field.fieldType === 'recordTypeLookup') {
          return 'recordTypeLookup';
        }

        if (field.codeList) {
          return 'codelist';
        }

        if (field.codeSet) {
          return 'codeset';
        }
        if (field.fieldType && this.dataModelStore.getRecordType(field.fieldType)) {
          this.lookupRecordType = field.fieldType;
          return 'recordType';
        }
        return 'default';
      }),
      shareReplay(1),
    );

    this.enumCodes$ = this.dataModelField$.pipe(
      filter((f) => f !== undefined && f.codeEnum !== undefined),
      map((field) => {
        return convertEnumToSet(field!.codeEnum!);
      }),
    );

    this.mapAreas$ = this.controlType$.pipe(
      untilDestroyed(this),
      filter((f) => f === 'mapArea'),
      withLatestFrom(of(this.quickSearchField)),
      map(([type, field]) => {
        // eslint-disable-next-line default-case
        return sortBy(
          this.tmConfig.getAllAreas().map((m) => {
            return { id: m.area, displayName: m.description };
          }),
          'displayName',
        );
      }),
      shareReplay(1),
    );

    this.recordTypes$ = this.controlType$.pipe(
      untilDestroyed(this),
      filter((f) => f === 'recordTypeLookup'),
      withLatestFrom(of(this.quickSearchField)),
      map(([type, field]) => {
        return sortBy(
          this.getRecordTypeLookupForField(field)
            .filter((f) => f.hasBackingTable ?? false)
            .map((m) => {
              return { id: m.id.toUpperCase(), displayName: m.displayNameSingular };
            }),
          'displayName',
        );
      }),
      shareReplay(1),
    );

    this.codeSetCodes$ = this.dataModelField$.pipe(
      filter((f) => f !== undefined && (f.codeSet !== undefined || f.codeList !== undefined)),
      switchMap((field) => {
        if (field?.codeList) {
          return of(field.codeList);
        }
        return this.codesApi.getCodes(field!.codeSet as string);
      }),
      map((codes) => {
        return codes.map((m) => {
          return { id: m.code, displayName: m.description };
        });
      }),
    );
  }

  private getRecordTypeLookupForField(field: QuickSearchField) {
    const dmField = this.dataModelStore.getFieldFromPath(field.recordType, field.fieldPath);
    if (!dmField) { return Array.from(this.dataModelStore.getRecordTypes()?.values() ?? []); }

    switch (dmField.recordTypeId?.toLowerCase()) {
      case 'note':
        return this.dataModelStore.getRecordTypesBySupportsFlag('notes');
      case 'contact':
        return this.dataModelStore.getRecordTypesBySupportsFlag('contacts');
      case 'task':
        return this.dataModelStore.getRecordTypesBySupportsFlag('tasks');
      case 'view':
        return this.dataModelStore.getRecordTypesBySupportsFlag('views');
      case 'documenttemplate':
        return this.dataModelStore.getRecordTypesBySupportsFlag('docTemplates');
      case 'workflow':
        return this.dataModelStore.getRecordTypesWithWorkflow();

      default:
        return Array.from(this.dataModelStore.getRecordTypes()?.values() ?? []);
    }

    return [];
  }

  lookup = (q: string): Observable<LookupResult[]> => {
    if (!this.lookupRecordType) {
      return of([]);
    }
    return this.filterStore.getSearchFieldLookup(this.lookupRecordType, q);
  };

  date1Changed(event: any) {
    this.formGroup.get(`${this.quickSearchField.fieldPath}.param1`)?.setValue(getDateOnlyString(event.value));
  }

  date2Changed(event: any) {
    this.formGroup.get(`${this.quickSearchField.fieldPath}.param2`)?.setValue(getDateOnlyString(event.value));
  }

  doSearch() {
    this.parent.doSearch();
  }
}
