import { Layout } from '../../layout/layout.types';

// Types
export type Scheme = 'auto' | 'dark' | 'light';
export type Screens = { [key: string]: string };
export type Theme = 'theme-default' | string;
export type Themes = { id: string; name: string; useDefaultColors?: boolean, imageExtension?: 'png' | 'jpg' | 'jpeg' | 'svg' }[];

/**
 * AppConfig interface. Update this interface to strictly type your config
 * object.
 */
export interface AppConfig {
  layout: Layout;
  scheme: Scheme;
  screens: Screens;
  theme: Theme;
  themes: Themes;
}

/**
 * Default configuration for the entire application. This object is used by
 * FuseConfigService to set the default configuration.
 *
 * If you need to store global configuration for your app, you can use this
 * object to set the defaults. To access, update and reset the config, use
 * FuseConfigService and its methods.
 *
 * "Screens" are carried over to the BreakpointObserver for accessing them within
 * components, and they are required.
 *
 * "Themes" are required for Tailwind to generate themes.
 */
export const appConfig: AppConfig = {
  layout: 'compact', //thin, compact, dense
  scheme: 'light',
  screens: {
    sm: '600px',
    md: '960px',
    lg: '1280px',
    xl: '1440px',
    xxl: '1920px',
    xxxl: '2560px',
    phone: '600px',
    tablet: '960px',
    laptop: '1280px',
    desktop: '1440px',
    wideMonitor: '1920px',
    ultraWideMonitor: '2560px',
  },
  theme: 'theme-trailblazer',
  themes: [
    {
      id: 'theme-trailblazer',
      name: 'Trailblazer',
    },
    {
      id: 'theme-branding',
      name: 'Branding',
    },
    {
      id: 'theme-carewest',
      name: 'Carewest',
    },
    {
      id: 'theme-schb',
      name: 'SCHB',
    },
    {
      id: 'theme-schb-new',
      name: 'SCHB New',
    },
    {
      id: 'theme-camcomp',
      name: 'CAMComp',
      useDefaultColors: true,
    },
    {
      id: 'theme-matsif',
      name: 'MATSIF',
      useDefaultColors: true,
      imageExtension: 'png'
    },
    {
      id: 'theme-savvi',
      name: 'Savvi',
      imageExtension: 'png'
    }
    // {
    //   id: 'theme-usrisk',
    //   name: 'usrisk'
    // }
  ],
};
