import { orderBy } from 'lodash-es';
import { Code } from 'portal-commons/dist/data-model/record-types/code';

export function convertCodeListToSet(codeList: Code[]): { id: string; displayName: string }[] {
  return codeList.map(m => {
    return { id: m.code, displayName: m.description }
  });
}

export function getCodeListDisplayValue(codeList: Code[], input: string | undefined | null | string[]) {
  if (input === null || input === undefined) { return input; }
  if (!Array.isArray(input)) {
    if (!!!input) { return input; }
    return codeList.find(f => f.code === input)?.description;
  }
  const resultSet = orderBy(codeList.filter(f => input.includes(f.code) || input.includes(f.id ?? '')).map(d => d.description), ['sequence', 'description']);
  return resultSet.join(', ');
}
