import { Pipe, PipeTransform } from '@angular/core';
import { splitCamelCase } from 'portal-commons/dist/data-model/meta-data-decorators';

@Pipe({ name: 'splitCamelCase' })
export class SplitCamelCasePipe implements PipeTransform {
  transform(input: string): string {
    if (!!!input) { return input; }
    return splitCamelCase(input);
  }
}
