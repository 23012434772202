var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'PolicyPaymentMethod';
let PolicyPaymentMethod = class PolicyPaymentMethod {
    id;
    policyHolderId;
    policyHolder;
    status;
    paymentToken;
    paymentNickName;
    maskedAccountNumber;
    transactionType; // "Ach | Manual | MasterCard | Visa | Discover | AmericanExpress " TODO: Replace "Manual" for "Other"
    expirationMonth;
    expirationYear;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyPaymentMethod.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Policyholder ID', fieldType: 'uuid', referenceType: 'PolicyHolder' })
], PolicyPaymentMethod.prototype, "policyHolderId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Policyholder ID', fieldType: 'PolicyHolder' })
], PolicyPaymentMethod.prototype, "policyHolder", void 0);
__decorate([
    FieldData({ recordTypeId })
], PolicyPaymentMethod.prototype, "status", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Token' })
], PolicyPaymentMethod.prototype, "paymentToken", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Nickname' })
], PolicyPaymentMethod.prototype, "paymentNickName", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Masked Account Number' })
], PolicyPaymentMethod.prototype, "maskedAccountNumber", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Transaction Type' })
], PolicyPaymentMethod.prototype, "transactionType", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Expiration Month' })
], PolicyPaymentMethod.prototype, "expirationMonth", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Expiration Year' })
], PolicyPaymentMethod.prototype, "expirationYear", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyPaymentMethod.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyPaymentMethod.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyPaymentMethod.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], PolicyPaymentMethod.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], PolicyPaymentMethod.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], PolicyPaymentMethod.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], PolicyPaymentMethod.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], PolicyPaymentMethod.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], PolicyPaymentMethod.prototype, "deletedUTC", void 0);
PolicyPaymentMethod = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        usesNewDataModel: true,
        displayNameSingular: 'Policy Payment Method',
        displayNamePlural: 'Policy Payment Methods',
    })
], PolicyPaymentMethod);
export { PolicyPaymentMethod };
